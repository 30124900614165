
/* eslint-disable */
import {Vue, Component} from "vue-property-decorator";
import TableColumn from "@/interfaces/TableColumn";
import AvitoCategoryTableListComponent
    from "@/components/TableList/TableData/AvitoCategories/AvitoCategoryTableListComponent.vue";

@Component({
    components: {
        AvitoCategoryTableListComponent
    }
})

export default class AvitoCategoriesPage extends Vue {
    columns: TableColumn[] = []
    currentPage = 1
    perPage = 100
    perPageVariants = [
        { active: false, perPage: 50 },
        { active: true, perPage: 100 },
        { active: false, perPage: 200 },
        { active: false, perPage: 500 }
    ]
    searchedText = ''
    filterText = ''
    loading = false
    timeout?: number


    get icon(): string {
        return this.loading ? 'arrow-clockwise' : 'search'
    }


    get animation(): string {
        return this.loading ? 'spin' : ''
    }


    get rows(): number {
        return this.filteredItems.length
    }


    get filteredItems() {
        var items = this.$store.getters['AvitoCategories/getAvitoCategories']

        if (this.filterText !== '') {
            items = items
                .filter((item): boolean => {
                    if (!item) {
                        return false
                    }

                    const {category_id, tree_path, google_sheet_id, name} = item

                    let treePathJoin = tree_path.join(' ');
                    let notFound = google_sheet_id == null ? 'Шаблон не найден / проверить' : '';

                    return (!!category_id && String(category_id).indexOf(this.filterText) !== -1)
                        || (!!notFound && notFound.toLowerCase().indexOf(this.filterText) !== -1)
                        || (!!name && name.toLowerCase().indexOf(this.filterText) !== -1)
                        || (!!treePathJoin && treePathJoin.toLowerCase().indexOf(this.filterText) !== -1);
                })
        }

        return items;
    }


    search(): void {
        clearTimeout(this.timeout)

        this.timeout = setTimeout(() => {
            setTimeout(() => this.loading = true)

            setTimeout(() => {
                this.filterText = this.searchedText.trim().toLowerCase()

                this.loading = false
            })
        }, 1000)
    }


    async created(): Promise<void> {
        document.title = 'Категории Авито'

        this.$store.dispatch('Users/redirectIfNotAdminOrManager')
            .then(async (res: boolean) => {
                if (res) {
                    return
                }

                this.$store.state.msg = ''
                this.$store.commit('SET_LOADING', true)
                await Promise.all([
                    this.$store.dispatch('AvitoCategories/setAvitoCategories'),
                    this.$store.dispatch('Tags/setTagsAvito'),
                ])
            })
            .catch(err => console.error(err))
            .finally(() => {
                this.$store.commit('SET_LOADING', false)
            })
    }


    changePaginate(variant: { active: boolean, perPage: number }): void {
        this.perPageVariants.forEach((variant) => variant.active = false)
        this.perPage = variant.perPage
        variant.active = true
    }
}
