import RootState from "@/interfaces/RootState";
import AvitoCategoriesState from "@/interfaces/AvitoCategoriesState";
import AvitoCategory from "@/interfaces/AvitoCategory";
import axios, { AxiosResponse } from "axios";
import { Module } from "vuex";

const AvitoCategories: Module<AvitoCategoriesState, RootState> = {
    namespaced: true,
    state: {
        avitoCategories: [] as AvitoCategory[],
        fixUpdate: ''
    },
    mutations: {
        SET_AVITO_CATEGORIES(state: AvitoCategoriesState, avitoCategories: AvitoCategory[]): void {
            state.avitoCategories = avitoCategories;
        },

        SET_FIX_UPDATE(state: AvitoCategoriesState, value: string): void {
            state.fixUpdate = value;
        },

        SORT_AVITO_CATEGORIES_TABLE_BY(
            state: AvitoCategoriesState,
            sort: keyof AvitoCategory
        ): void {
            state.avitoCategories.sort((a: AvitoCategory, b: AvitoCategory) => {
                const bElement = b[sort];
                const aElement = a[sort];
                return ((bElement ? bElement : 0) > (aElement ? aElement : 0)) ? -1 : 1;
            });
        },
        UPDATE_CATEGORY_NAME(state: AvitoCategoriesState, { category_id, name }: { category_id: number, name: string }): void {
            const category = state.avitoCategories.find(cat => cat.category_id === category_id);
            if (category) {
                category.name = name;
            }
        },
        UPDATE_NEW_CUSTOM_NAME(
            state: AvitoCategoriesState,
            { category_id, new_custom_name }: { category_id: number, new_custom_name: string }
        ): void {
            const category = state.avitoCategories.find(cat => cat.category_id === category_id);
            if (category) {
                category.new_custom_name = new_custom_name;
            }
        },

        UPDATE_CUSTOM_NAME(state: AvitoCategoriesState, { category_id, new_custom_name }: { category_id: number, new_custom_name: string }): void {
            const category = state.avitoCategories.find(cat => cat.category_id === category_id);
            if (category) {
                category.custom_name = new_custom_name;
                category.google_sheet_id = null;
                category.tab_id = null;
            }
        },

        UPDATE_GOOGLE_SHEET_ID(state, { categoryId, googleSheetId, tabId }) {
            const category = state.avitoCategories.find(cat => cat.category_id === categoryId);
            if (category) {
                category.google_sheet_id = googleSheetId;
                category.tab_id = tabId;
            }
        }
    },
    actions: {
        async setAvitoCategories({ commit, dispatch, rootState, rootGetters }): Promise<void> {
            const url = await dispatch('getFullUrl', 'avito/categories', { root: true });
            await axios.get<AvitoCategory[]>(
                url + rootGetters.hashQuery,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse<AvitoCategory[]>) => {
                    const statusCode = res.request.status;
                    switch (statusCode) {
                        case 200:
                            const categories = res.data;
                            commit('SET_AVITO_CATEGORIES', categories);
                            commit('SORT_AVITO_CATEGORIES_TABLE_BY', 'category_id');
                            rootState.hashValidated = true;

                            categories.forEach(category => {
                                commit('UPDATE_NEW_CUSTOM_NAME', { category_id: category.category_id, new_custom_name: category.custom_name || '' });
                                dispatch('computeCategoryName', category.category_id);
                            });

                            break;
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true });
                    }
                })
                .catch(err => console.error(err));
        },

        async saveCategory({ commit, dispatch, rootGetters }: { commit: Function, dispatch: Function, rootGetters: any }, category: AvitoCategory): Promise<void> {
            const url = await dispatch('getFullUrl', 'avito/categories', { root: true });
            const method = 'put';

            await axios({
                method,
                url: url + rootGetters.hashQuery,
                data: {
                    category_id: category.category_id,
                    name: category.new_custom_name
                },
                validateStatus: (): boolean => true
            })
                .then<void, never>((res: AxiosResponse) => {
                    const statusCode = res.request.status;
                    if (statusCode === 200 || statusCode === 201) {
                        commit('UPDATE_CUSTOM_NAME', { category_id: category.category_id, new_custom_name: category.new_custom_name });
                        dispatch('computeCategoryName', category.category_id);
                        commit('SET_FIX_UPDATE', Math.random());
                    } else {
                        commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true });
                    }
                })
                .catch(err => console.error(err));
        },

        computeCategoryName({ state, commit }: { state: AvitoCategoriesState, commit: Function }, categoryId: number): void {
            const category = state.avitoCategories.find(cat => cat.category_id === categoryId);
            if (category) {
                const customName = category.new_custom_name || category.custom_name;

                const name = 'Avito - ' + (customName ? customName + '/' : '') + category.tree_path[category.tree_path.length - 1];

                commit('UPDATE_CATEGORY_NAME', { category_id: categoryId, name });
            }
        },

        updateNewCustomName({ commit, dispatch }: { commit: Function, dispatch: Function }, { category_id, new_custom_name }: { category_id: number, new_custom_name: string }): void {
            commit('UPDATE_NEW_CUSTOM_NAME', { category_id, new_custom_name });

            commit('SET_FIX_UPDATE', Math.random());
        },

        async checkTemplate({ commit, dispatch, rootGetters }, { googleSheetId, name, categoryId }): Promise<void> {
            const url = await dispatch('getFullUrl', 'avito/categories/check', { root: true });

            await axios.post(url + rootGetters.hashQuery, {
                google_sheet_id: googleSheetId,
                name: name,
                category_id: categoryId
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
                validateStatus: (): boolean => true
            })
                .then<void, never>((res: AxiosResponse) => {
                    const statusCode = res.request.status;
                    if (statusCode === 200 && res.data) {
                        commit('UPDATE_GOOGLE_SHEET_ID', {
                            categoryId,
                            googleSheetId: res.data.google_sheet_id,
                            tabId: res.data.tab_id
                        });
                    } else {
                        commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true });
                    }
                })
                .catch(err => console.error(err));
        }
    },
    getters: {
        getAvitoCategories(state: AvitoCategoriesState): AvitoCategory[] {
            return state.avitoCategories;
        },

        issetAvitoCategoriesNewCategory(state: AvitoCategoriesState): boolean {
            return state.avitoCategories.map(el => el.category_id).includes(0);
        },

        getFixUpdate(state: AvitoCategoriesState): string {
            return state.fixUpdate;
        }
    }
};

export default AvitoCategories;
