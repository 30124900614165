
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import { Action } from "vuex-class";

@Component
export default class TDAvitoCategoryCustomName extends Vue {
    @Prop() item!: { tree_path: string[], custom_name: string | null };

    selectedValue: string = "";
    customText: string = "";
    showCustomTextInput: boolean = false;

    static componentName = 'TDAvitoCategoryCustomName';

    get treePathWithoutLast(): string[] {
        return this.item.tree_path.slice(0, -1);
    }

    @Action("AvitoCategories/updateNewCustomName") updateNewCustomName!: ({ category_id, new_custom_name }: { category_id: number; new_custom_name: string }) => void;

    mounted() {
        this.initializeValues();
    }

    @Watch('item', { deep: true })
    onItemChanged() {
        this.initializeValues();
    }

    initializeValues() {
        const { custom_name } = this.item;
        if (custom_name == '' || custom_name == null) {
            this.selectedValue = "";
            this.customText = "";
        } else if (this.treePathWithoutLast.includes(custom_name)) {
            this.selectedValue = custom_name;
            this.customText = "";
        } else {
            this.selectedValue = "customText";
            this.customText = custom_name;
        }
        this.showCustomTextInput = this.selectedValue === "customText";
    }

    handleSelectionChange() {
        this.showCustomTextInput = this.selectedValue === "customText";
        if (this.selectedValue !== "customText") {
            this.commitCustomName(this.selectedValue);
        }
    }

    handleCustomTextChange() {
        this.commitCustomName(this.customText);
    }

    commitCustomName(newCustomName: string) {
        this.updateNewCustomName({
            category_id: this.item.category_id,
            new_custom_name: newCustomName,
        });
    }
}
