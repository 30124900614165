export default [
    {
        name: 'Avito ID',
        code: 'avitoCategoryId',
    },
    {
        name: 'Категория',
        code: 'avitoCategoryTreePath',
    },
    {
        name: 'Название шаблона',
        code: 'avitoCategoryTemplateName',
    },
    {
        name: 'Префикс',
        code: 'avitoCategoryCustomName',
    },
    {
        name: '',
        code: 'avitoCategorySave',
    },
]
