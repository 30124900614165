
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class TDAvitoCategoryTreePath extends Vue {
    @Prop() item!: { tree_path: string[] }

    static componentName = 'TDAvitoCategoryTreePath'

    getFormattedTreePath(): string {
        if (!this.item.tree_path || this.item.tree_path.length === 0) return '';

        return this.item.tree_path
            .map((path, index) => {
                const arrow = index > 0 ? ' → ' : '';
                return `<div style="margin-left: ${index * 10}px;">${arrow}${path}</div>`;
            })
            .join('');
    }
}
