import { render, staticRenderFns } from "./AvitoCategoryRowComponent.vue?vue&type=template&id=875eefb8&scoped=true"
import script from "./AvitoCategoryRowComponent.vue?vue&type=script&lang=ts"
export * from "./AvitoCategoryRowComponent.vue?vue&type=script&lang=ts"
import style0 from "./AvitoCategoryRowComponent.vue?vue&type=style&index=0&id=875eefb8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "875eefb8",
  null
  
)

export default component.exports