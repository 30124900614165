
import {Vue, Prop, Component} from "vue-property-decorator";
import TableItem from "@/interfaces/TableItem"
import TableColumn from "@/interfaces/TableColumn"
import TableItemComponent from "./TableItemComponent.vue"
import TableHeaderComponent from "@/components/TableList/TableHeaderComponent.vue";

@Component({
    components: {
        TableHeaderComponent,
        TableItemComponent,
    },
})
export default class TableListComponent extends Vue {
    @Prop() columns?: TableColumn[]
    @Prop() items!
    @Prop() perPage?: number
    @Prop() currentPage?: number
    @Prop({ default: 'tableGuid' }) keyField!: string

    getKey(item: TableItem): string {
        return item[this.keyField] || item.tableGuid
    }

    get textAddFirst(): string {
        switch (this.$router.currentRoute.name) {
            case 'marketplaces':
                return this.$store.state.text.addFirstMarketplace
            case 'tables':
                return this.$store.state.text.addFirstTable
            default:
                return ''
        }
    }

    get itemsPaginated(): TableItem[] {
        if (!!this.perPage && !!this.currentPage && (this.perPage < this.items.length)) {
            const perPage = this.perPage

            const start = (this.currentPage - 1) * perPage
            const end = start + perPage

            return this.items.slice(start, end)
        }

        return this.items
    }
}
