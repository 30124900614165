
import TDAdminMaxAds from "@/components/TableList/TableData/TDAdminMaxAds.vue"
import TDAmount from "@/components/TableList/TableData/TDAmount.vue"
import TDDiscount from "@/components/TableList/TableData/TDDiscount.vue"
import TDIncome from "@/components/TableList/TableData/TDIncome.vue"
import TDGoogleSheetLink from "@/components/TableList/TableData/TDGoogleSheetLink.vue"
import TDAdminDate from "@/components/TableList/TableData/TDAdminDate.vue"
import TDAdminGenerators from "@/components/TableList/TableData/TDAdminGenerators.vue"
import TDAdminText from "@/components/TableList/TableData/TDAdminText.vue"
import TDArchiveUser from "@/components/TableList/TableData/TDArchiveUser.vue"
import TDDate from "@/components/TableList/TableData/TDDate.vue"
import TDDeleteTable from "@/components/TableList/TableData/TDDeleteTable.vue"
import TDGenerators from "@/components/TableList/TableData/TDGenerators.vue"
import TDIndex from "@/components/TableList/TableData/TDIndex.vue"
import TDInvitationLink from "@/components/TableList/TableData/TDInvitationLink.vue"
import TDInvitationNotes from "@/components/TableList/TableData/TDInvitationNotes.vue"
import TDMaxAds from "@/components/TableList/TableData/TDMaxAds.vue"
import TDReferrals from "@/components/TableList/TableData/TDReferrals.vue"
import TDReferralsNotes from "@/components/TableList/TableData/TDReferralsNotes.vue"
import TDRefreshUserToken from "@/components/TableList/TableData/TDRefreshUserToken.vue"
import TDSaveTable from "@/components/TableList/TableData/TDSaveTable.vue"
import TDAdminTableActions from "@/components/TableList/TableData/TDAdminTableActions.vue"
import TDSaveUser from "@/components/TableList/TableData/TDSaveUser.vue"
import TDSaveTag from "@/components/TableList/TableData/TDSaveTag.vue"
import TDShowTable from "@/components/TableList/TableData/TDShowTable.vue"
import TDTableNotes from "@/components/TableList/TableData/TDTableNotes.vue"
import TDText from "@/components/TableList/TableData/TDText.vue"
import TDRestoreUser from "@/components/TableList/TableData/TDRestoreUser.vue"
import TDTokenShort from "@/components/TableList/TableData/TDTokenShort.vue"
import TDUserToken from "@/components/TableList/TableData/TDUserToken.vue"
import TDMonitoringStatus from "@/components/TableList/TableData/TDMonitoringStatus.vue"
import TDMonitoringPosition from "@/components/TableList/TableData/TDMonitoringPosition.vue"
import TDMonitoringNotifications from "@/components/TableList/TableData/TDMonitoringNotifications.vue"
import TDMonitoringButtons from "@/components/TableList/TableData/TDMonitoringButtons.vue"
import TDMessengerToken from "@/components/TableList/TableData/TDMessengerToken.vue"
import TDMessengerRefreshToken from "@/components/TableList/TableData/TDMessengerRefreshToken.vue"
import TDMessengerDelete from "@/components/TableList/TableData/TDMessengerDelete.vue"
import TDMessengerStatus from "@/components/TableList/TableData/TDMessengerStatus.vue"
import TDMessengerNotes from "@/components/TableList/TableData/TDMessengerNotes.vue"
import TDBalanceTransaction from "@/components/TableList/TableData/TDBalanceTransaction.vue"
import TDPremiumSwitch from "@/components/TableList/TableData/TDPremiumSwitch.vue"
import TDUserIsBlocked from "@/components/TableList/TableData/TDUserIsBlocked.vue"
import TDRoleId from "@/components/TableList/TableData/TDRoleId.vue"
import TDAvitoServicesTitle from "@/components/TableList/TableData/TDAvitoServicesTitle.vue"
import TDAvitoServicesCity from "@/components/TableList/TableData/TDAvitoServicesCity.vue"
import TDAvitoServicesRangeParam from "@/components/TableList/TableData/TDAvitoServicesRangeParam.vue"
import TDAvitoServicesServices from "@/components/TableList/TableData/TDAvitoServicesServices.vue"
import TDAvitoServicesDateStart from "@/components/TableList/TableData/TDAvitoServicesDateStart.vue"
import TDAvitoServicesDateExpired from "@/components/TableList/TableData/TDAvitoServicesDateExpired.vue"
import TDAvitoServicesBudget from "@/components/TableList/TableData/TDAvitoServicesBudget.vue"
import TDAvitoServicesSchedule from "@/components/TableList/TableData/TDAvitoServicesSchedule.vue"
import TDAvitoServicesSave from "@/components/TableList/TableData/TDAvitoServicesSave.vue"
import TDEditAvitoRules from "@/components/TableList/TableData/TDEditAvitoRules.vue";

import TDAvitoRepubDeleteRule from "@/components/TableList/TableData/TDAvitoRepubDeleteRule.vue"
import TDAvitoRepubParams from "@/components/TableList/TableData/TDAvitoRepubParams.vue"
import TDAvitoRepubSave from "@/components/TableList/TableData/TDAvitoRepubSave.vue"
import TDAvitoRepubTitle from "@/components/TableList/TableData/TDAvitoRepubTitle.vue"
import TDAvitoRepubCity from "@/components/TableList/TableData/TDAvitoRepubCity.vue"
import TDAvitoRepubRepubRule from "@/components/TableList/TableData/TDAvitoRepubRepubRule.vue"

import TDAvitoRepubEnabled_v2 from "@/components/TableList/TableData/AvitoRepub_v2/TDAvitoRepubEnabled.vue"
import TDAvitoRepubCities_v2 from "@/components/TableList/TableData/AvitoRepub_v2/TDAvitoRepubCities.vue"
import TDAvitoRepubTitles_v2 from "@/components/TableList/TableData/AvitoRepub_v2/TDAvitoRepubTitles.vue"
import TDAvitoRepubRandomize_v2 from "@/components/TableList/TableData/AvitoRepub_v2/TDAvitoRepubRandomize.vue"
import TDAvitoRepubIdsExclude_v2 from "@/components/TableList/TableData/AvitoRepub_v2/TDAvitoRepubIdsExclude.vue"
import TDAvitoRepubSheetsExclude_v2 from "@/components/TableList/TableData/AvitoRepub_v2/TDAvitoRepubSheetsExclude.vue"
import TDAvitoRepubPubDays_v2 from "@/components/TableList/TableData/AvitoRepub_v2/TDAvitoRepubPubDays.vue"
import TDAvitoRepubConditions_v2 from "@/components/TableList/TableData/AvitoRepub_v2/TDAvitoRepubConditions.vue"
import TDAvitoRepubActions_v2 from "@/components/TableList/TableData/AvitoRepub_v2/TDAvitoRepubActions.vue"

import TDAvitoRepubHistoryCreated_v2 from "@/components/TableList/TableData/AvitoRepub_v2/TDAvitoRepubHistoryCreated_v2.vue"
import TDAvitoRepubHistoryPubDay_v2 from "@/components/TableList/TableData/AvitoRepub_v2/TDAvitoRepubHistoryPubDay_v2.vue"
import TDAvitoRepubHistoryAvitoId_v2 from "@/components/TableList/TableData/AvitoRepub_v2/TDAvitoRepubHistoryAvitoId_v2.vue"
import TDAvitoRepubHistoryAdId_v2 from "@/components/TableList/TableData/AvitoRepub_v2/TDAvitoRepubHistoryAdId_v2.vue"
import TDAvitoRepubHistoryAdDate_v2 from "@/components/TableList/TableData/AvitoRepub_v2/TDAvitoRepubHistoryAdDate_v2.vue"
import TDAvitoRepubHistoryRandomize_v2 from "@/components/TableList/TableData/AvitoRepub_v2/TDAvitoRepubHistoryRandomize_v2.vue"
import TDAvitoRepubHistoryStats_v2 from "@/components/TableList/TableData/AvitoRepub_v2/TDAvitoRepubHistoryStats_v2.vue"
import TDAvitoRepubHistoryConditions_v2 from "@/components/TableList/TableData/AvitoRepub_v2/TDAvitoRepubHistoryConditions_v2.vue"

import TDAvitoCategoryId from "@/components/TableList/TableData/AvitoCategories/TDAvitoCategoryId.vue"
import TDAvitoCategoryTreePath from "@/components/TableList/TableData/AvitoCategories/TDAvitoCategoryTreePath.vue"
import TDAvitoCategoryCustomName from "@/components/TableList/TableData/AvitoCategories/TDAvitoCategoryCustomName.vue"
import TDAvitoCategoryTemplateName from "@/components/TableList/TableData/AvitoCategories/TDAvitoCategoryTemplateName.vue"
import TDAvitoCategorySave from "@/components/TableList/TableData/AvitoCategories/TDAvitoCategorySave.vue"

import TDImagesTransformEnabled from "@/components/TableList/TableData/ImagesTransform/TDImagesTransformEnabled.vue"
import TDImagesTransformTitles from "@/components/TableList/TableData/ImagesTransform/TDImagesTransformTitles.vue"
import TDImagesTransformCities from "@/components/TableList/TableData/ImagesTransform/TDImagesTransformCities.vue"
import TDImagesTransformIdsExclude from "@/components/TableList/TableData/ImagesTransform/TDImagesTransformIdsExclude.vue"
import TDImagesTransformSheetsExclude from "@/components/TableList/TableData/ImagesTransform/TDImagesTransformSheetsExclude.vue"
import TDImagesTransformStrength from "@/components/TableList/TableData/ImagesTransform/TDImagesTransformStrength.vue"
import TDImagesTransformActions from "@/components/TableList/TableData/ImagesTransform/TDImagesTransformActions.vue"

import TDNull from "@/components/TableList/TableData/TDNull.vue"
import { invitation } from "@/types/invitations"
import { referral } from "@/types/referrals"
import { adminTransaction, transaction } from "@/types/transactions"
import { Component, Vue, Prop } from 'vue-property-decorator'
import TableItem from "@/interfaces/TableItem"
import TableColumn from "@/interfaces/TableColumn"
import User from "@/interfaces/User";
import AvitoRepubRule from "@/interfaces/AvitoRepubRule";
import AvitoRepubRule_v2 from "@/interfaces/AvitoRepubRule_v2";
import AvitoRepubHistory_v2 from "@/interfaces/AvitoRepubHistory_v2";
import AvitoServicesRule from "@/interfaces/AvitoServicesRule";
import Tag from "@/interfaces/Tag";
import AvitoCategory from "@/interfaces/AvitoCategory";

@Component({
    components: {
        TDNull,
        TDAdminGenerators,
        TDAdminText,
        TDAdminDate,
        TDGenerators,
        TDGoogleSheetLink,
        TDText,
        TDArchiveUser,
        TDRefreshUserToken,
        TDDate,
        TDIndex,
        TDTokenShort,
        TDShowTable,
        TDDeleteTable,
        TDRestoreUser,
        TDUserToken,
        TDSaveTable,
        TDAdminTableActions,
        TDSaveUser,
        TDSaveTag,
        TDTableNotes,
        TDAmount,
        TDInvitationLink,
        TDInvitationNotes,
        TDDiscount,
        TDReferrals,
        TDReferralsNotes,
        TDMaxAds,
        TDAdminMaxAds,
        TDIncome,
        TDMonitoringStatus,
        TDMonitoringPosition,
        TDMonitoringNotifications,
        TDMonitoringButtons,
        TDMessengerStatus,
        TDMessengerToken,
        TDMessengerRefreshToken,
        TDMessengerNotes,
        TDMessengerDelete,
        TDBalanceTransaction,
        TDPremiumSwitch,
        TDUserIsBlocked,
        TDRoleId,
        TDAvitoServicesTitle,
        TDAvitoServicesCity,
        TDAvitoServicesRangeParam,
        TDAvitoServicesServices,
        TDAvitoServicesDateStart,
        TDAvitoServicesDateExpired,
        TDAvitoServicesBudget,
        TDAvitoServicesSchedule,
        TDAvitoServicesSave,
        TDEditAvitoRules,

        TDAvitoRepubTitle,
        TDAvitoRepubCity,
        TDAvitoRepubRepubRule,
        TDAvitoRepubDeleteRule,
        TDAvitoRepubParams,
        TDAvitoRepubSave,

        TDAvitoRepubEnabled_v2,
        TDAvitoRepubCities_v2,
        TDAvitoRepubTitles_v2,
        TDAvitoRepubRandomize_v2,
        TDAvitoRepubIdsExclude_v2,
        TDAvitoRepubSheetsExclude_v2,
        TDAvitoRepubPubDays_v2,
        TDAvitoRepubConditions_v2,
        TDAvitoRepubActions_v2,

        TDAvitoRepubHistoryCreated_v2,
        TDAvitoRepubHistoryPubDay_v2,
        TDAvitoRepubHistoryAvitoId_v2,
        TDAvitoRepubHistoryAdId_v2,
        TDAvitoRepubHistoryAdDate_v2,
        TDAvitoRepubHistoryRandomize_v2,
        TDAvitoRepubHistoryStats_v2,
        TDAvitoRepubHistoryConditions_v2,

        TDAvitoCategoryId,
        TDAvitoCategoryTreePath,
        TDAvitoCategoryCustomName,
        TDAvitoCategoryTemplateName,
        TDAvitoCategorySave,

        TDImagesTransformEnabled,
        TDImagesTransformTitles,
        TDImagesTransformCities,
        TDImagesTransformIdsExclude,
        TDImagesTransformSheetsExclude,
        TDImagesTransformStrength,
        TDImagesTransformActions
    }
})
export default class TableDataComponent extends Vue {
    @Prop() index!: number;
    @Prop() item!: TableItem | User | transaction | adminTransaction | invitation | referral | Tag | AvitoRepubRule | AvitoRepubRule_v2 | AvitoRepubHistory_v2 | AvitoServicesRule | AvitoCategory;
    @Prop() column!: TableColumn;

    component = TDNull.componentName;

    contentClass = ''

    get isTransaction(): boolean {
        return 'referralId' in this.item
    }

    created(): void {
        this.contentClass = this.column.class ? this.column.class : ''

        switch (this.column.code) {
            case 'generators':
                if (this.$store.getters["Users/userIsAdmin"]
                    || this.$store.getters["Users/userIsAdminLite"]
                    || this.$store.getters["Users/userIsManager"]
                ) {
                    this.component = TDAdminGenerators.componentName
                    break
                }
                this.component = TDGenerators.componentName
                break
            case 'maxAds':
                if (this.$store.getters["Users/userIsAdmin"]
                    || this.$store.getters["Users/userIsAdminLite"]
                    || this.$store.getters["Users/userIsManager"]
                ) {
                    this.component = TDAdminMaxAds.componentName
                    break
                }
                this.component = TDMaxAds.componentName
                break
            case 'googleSheetUrl':
                this.component = TDGoogleSheetLink.componentName
                break
            case 'archive_user':
                this.component = TDArchiveUser.componentName
                break
            case 'restore_user':
                this.component = TDRestoreUser.componentName
                break
            case 'refreshUserToken':
                this.component = TDRefreshUserToken.componentName
                break
            case 'token':
                this.component = TDUserToken.componentName
                break
            case 'dateExpired':
                if (this.$store.getters["Users/userIsAdmin"]
                    || this.$store.getters["Users/userIsAdminLite"]
                    || this.$store.getters["Users/userIsManager"]
                ) {
                    this.component = TDAdminDate.componentName
                    break
                }
                this.component = TDDate.componentName
                break
            case 'index':
                this.component = TDIndex.componentName
                break
            case 'token_short':
                this.component = TDTokenShort.componentName
                break
            case 'show_table':
                this.component = TDShowTable.componentName
                break
            case 'delete_table':
                this.component = TDDeleteTable.componentName
                break
            case 'save_table':
                this.component = TDSaveTable.componentName
                break
            case 'admin_table_actions':
                this.component = TDAdminTableActions.componentName
                break
            case 'save_user':
                this.component = TDSaveUser.componentName
                break
            case 'save_tag':
                this.component = TDSaveTag.componentName
                break
            case 'name':
            case 'phoneNumber':
            case 'socialNetworkUrl':
            case 'comment':
            case 'colName':
            case 'tag':
            case 'innerTag':
                if ((this.$store.getters["Users/userIsAdmin"]
                        || this.$store.getters["Users/userIsAdminLite"]
                        || this.$store.getters["Users/userIsManager"])
                    && !this.isTransaction)
                {
                    this.component = TDAdminText.componentName
                    break
                }
                this.component = TDText.componentName
                break
            case 'tableNotes':
            case 'marketplacesNotes':
                this.component = TDTableNotes.componentName
                break
            case 'invitationHash':
                this.component = TDInvitationLink.componentName
                break
            case 'invitationNotes':
                this.component = TDInvitationNotes.componentName
                break
            case 'referralsNotes':
                this.component = TDReferralsNotes.componentName
                break
            case 'income':
                this.component = TDIncome.componentName
                break
            case 'discount':
                this.component = TDDiscount.componentName
                break
            case 'referralsAndActiveReferrals':
                this.component = TDReferrals.componentName
                break
            case 'period':
                // @ts-expect-error whatever
                this.component = this.item.period ? TDText.componentName : TDNull.componentName;
                break
            case 'monitoringStatus':
                this.component = TDMonitoringStatus.componentName
                break
            case 'monitoringPosition':
                this.component = TDMonitoringPosition.componentName
                break
            case 'monitoringNotifications':
                this.component = TDMonitoringNotifications.componentName
                break
            case 'monitoringButtons':
                this.component = TDMonitoringButtons.componentName
                break
            case 'messengerToken':
                this.component = TDMessengerToken.componentName
                break
            case 'messengerRefreshToken':
                this.component = TDMessengerRefreshToken.componentName
                break
            case 'messengerDelete':
                this.component = TDMessengerDelete.componentName
                break
            case 'messengerStatus':
                this.component = TDMessengerStatus.componentName
                break
            case 'messengerNotes':
                this.component = TDMessengerNotes.componentName
                break
            case 'balanceTransaction':
                this.component = TDBalanceTransaction.componentName
                break
            case 'premiumSwitch':
                this.component = TDPremiumSwitch.componentName
                break
            case 'isBlocked':
                this.component = TDUserIsBlocked.componentName
                break
            case 'roleId':
                this.component = TDRoleId.componentName
                break

            case 'avitoRepubTitle':
                this.component = TDAvitoRepubTitle.componentName
                break
            case 'avitoRepubCity':
                this.component = TDAvitoRepubCity.componentName
                break
            case 'avitoRepubRepubRule':
                this.component = TDAvitoRepubRepubRule.componentName
                break
            case 'avitoRepubDeleteRule':
                this.component = TDAvitoRepubDeleteRule.componentName
                break
            case 'avitoRepubParams':
                this.component = TDAvitoRepubParams.componentName
                break
            case 'avitoRepubSave':
                this.component = TDAvitoRepubSave.componentName
                break

            case 'avitoRepubEnable_v2':
                this.component = TDAvitoRepubEnabled_v2.componentName
                break
            case 'avitoRepubTitles_v2':
                this.component = TDAvitoRepubTitles_v2.componentName
                break
            case 'avitoRepubCities_v2':
                this.component = TDAvitoRepubCities_v2.componentName
                break
            case 'avitoRepubRandomize_v2':
                this.component = TDAvitoRepubRandomize_v2.componentName
                break
            case 'avitoRepubIdsExclude_v2':
                this.component = TDAvitoRepubIdsExclude_v2.componentName
                break
            case 'avitoRepubSheetsExclude_v2':
                this.component = TDAvitoRepubSheetsExclude_v2.componentName
                break
            case 'avitoRepubPubDays_v2':
                this.component = TDAvitoRepubPubDays_v2.componentName
                break
            case 'avitoRepubConditions_v2':
                this.component = TDAvitoRepubConditions_v2.componentName
                break
            case 'avitoRepubActions_v2':
                this.component = TDAvitoRepubActions_v2.componentName
                break

            case 'avitoCategoryId':
                this.component = TDAvitoCategoryId.componentName
                break
            case 'avitoCategoryTreePath':
                this.component = TDAvitoCategoryTreePath.componentName
                break
            case 'avitoCategoryCustomName':
                this.component = TDAvitoCategoryCustomName.componentName
                break
            case 'avitoCategoryTemplateName':
                this.component = TDAvitoCategoryTemplateName.componentName
                break
            case 'avitoCategorySave':
                this.component = TDAvitoCategorySave.componentName
                break

            case 'avitoRepubHistoryCreated_v2':
                this.component = TDAvitoRepubHistoryCreated_v2.componentName
                break
            case 'avitoRepubHistoryPubDay_v2':
                this.component = TDAvitoRepubHistoryPubDay_v2.componentName
                break
            case 'avitoRepubHistoryAvitoId_v2':
                this.component = TDAvitoRepubHistoryAvitoId_v2.componentName
                break
            case 'avitoRepubHistoryAdId_v2':
                this.component = TDAvitoRepubHistoryAdId_v2.componentName
                break
            case 'avitoRepubHistoryAdDate_v2':
                this.component = TDAvitoRepubHistoryAdDate_v2.componentName
                break
            case 'avitoRepubHistoryRandomize_v2':
                this.component = TDAvitoRepubHistoryRandomize_v2.componentName
                break
            case 'avitoRepubHistoryStats_v2':
                this.component = TDAvitoRepubHistoryStats_v2.componentName
                break
            case 'avitoRepubHistoryConditions_v2':
                this.component = TDAvitoRepubHistoryConditions_v2.componentName
                break

            case 'imagesTransformEnable':
                this.component = TDImagesTransformEnabled.componentName
                break
            case 'imagesTransformTitles':
                this.component = TDImagesTransformTitles.componentName
                break
            case 'imagesTransformCities':
                this.component = TDImagesTransformCities.componentName
                break
            case 'imagesTransformIdsExclude':
                this.component = TDImagesTransformIdsExclude.componentName
                break
            case 'imagesTransformSheetsExclude':
                this.component = TDImagesTransformSheetsExclude.componentName
                break
            case 'imagesTransformStrength':
                this.component = TDImagesTransformStrength.componentName
                break
            case 'imagesTransformActions':
                this.component = TDImagesTransformActions.componentName
                break

            case 'avitoServicesTitle':
                this.component = TDAvitoServicesTitle.componentName
                break
            case 'avitoServicesCity':
                this.component = TDAvitoServicesCity.componentName
                break
            case 'avitoServicesRangeParam':
                this.component = TDAvitoServicesRangeParam.componentName
                break
            case 'avitoServicesServices':
                this.component = TDAvitoServicesServices.componentName
                break
            case 'avitoServicesDateExpired':
                this.component = TDAvitoServicesDateExpired.componentName
                break
            case 'avitoServicesDateStart':
                this.component = TDAvitoServicesDateStart.componentName
                break
            case 'avitoServicesBudget':
                this.component = TDAvitoServicesBudget.componentName
                break
            case 'avitoServicesSchedule':
                this.component = TDAvitoServicesSchedule.componentName
                break
            case 'avitoServicesSave':
                this.component = TDAvitoServicesSave.componentName
                break
            case 'editAvitoRules':
                this.component = TDEditAvitoRules.componentName
                break
            default:
                this.component = TDText.componentName
        }
    }
}
