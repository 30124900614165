
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";

@Component
export default class TDAvitoCategoryTemplateName extends Vue {
    @Prop() item!: any;

    @Getter("AvitoCategories/getFixUpdate") fixUpdate!: string;

    @Action("AvitoCategories/checkTemplate") checkTemplate!: (payload: { googleSheetId: string, name: string, categoryId: number }) => Promise<void>;

    googleSheetInput: string = '';
    isModalVisible: boolean = false;
    isChecking: boolean = false;
    checkResult: 'success' | null = null;

    get isSubmitDisabled() {
        return this.googleSheetInput.trim === '';
    }

    openModal() {
        this.googleSheetInput = '';
        this.isModalVisible = true;
    }

    async submitForm() {
        if (this.googleSheetInput.trim() === '') {
            return;
        }

        this.isModalVisible = false;

        this.isChecking = true;
        this.checkResult = null;

        try {
            await this.checkTemplate({
                googleSheetId: this.googleSheetInput,
                name: this.item.name,
                categoryId: this.item.category_id,
            });
            this.checkResult = 'success';
        } finally {
            this.isChecking = false;
        }
    }

    static componentName = 'TDAvitoCategoryTemplateName';
}
