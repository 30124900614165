
/* eslint-disable */
import {Vue, Component} from "vue-property-decorator";
import TagsAvitoTableListComponent from "@/components/Tags/TagsAvitoTableListComponent.vue";
import TableColumn from "@/interfaces/TableColumn";
import TDAddTag from "@/components/TableList/TableData/TDAddTag.vue";
import Tag from "@/interfaces/Tag";

@Component({
    components: {
        TagsAvitoTableListComponent,
        TDAddTag
    }
})

export default class TagsAvitoPage extends Vue {
    columns: TableColumn[] = []
    currentPage = 1
    perPage = 100
    perPageVariants = [
        { active: false, perPage: 50 },
        { active: true, perPage: 100 },
        { active: false, perPage: 200 },
        { active: false, perPage: 500 }
    ]
    searchedText = ''
    filterText = ''
    loading = false
    timeout?: number

    get icon(): string {
        return this.loading ? 'arrow-clockwise' : 'search'
    }

    get animation(): string {
        return this.loading ? 'spin' : ''
    }

    get rows(): number {
        return this.tags.length
    }

    get tags(): Tag[] {
        var tags = this.$store.getters['Tags/getTagsAvito']

        if (this.filterText !== '') {
            tags = tags
                .filter((tagItem): boolean => {
                    if (!tagItem) {
                        return false
                    }

                    const {id, colName, tag, innerTag, comment} = tagItem

                    return (!!colName && colName.toLowerCase().indexOf(this.filterText) !== -1) ||
                        (!!tag && tag.toLowerCase().indexOf(this.filterText) !== -1) ||
                        (!!innerTag && innerTag.toLowerCase().indexOf(this.filterText) !== -1) ||
                        (!!comment && comment.toLowerCase().indexOf(this.filterText) !== -1) ||
                        (id === 0);
                })
        }

        return tags;
    }

    search(): void {
        clearTimeout(this.timeout)

        this.timeout = setTimeout(() => {
            setTimeout(() => this.loading = true)

            setTimeout(() => {
                this.filterText = this.searchedText.trim().toLowerCase()

                this.loading = false
            })
        }, 1000)
    }

    async created(): Promise<void> {
        document.title = 'Теги Авито'

        this.$store.dispatch('Users/redirectIfNotAdminOrManager')
            .then(async (res: boolean) => {
                if (res) {
                    return
                }

                this.$store.state.msg = ''
                this.$store.commit('SET_LOADING', true)
                await Promise.all([
                    this.$store.dispatch('Tags/setTagsAvito'),
                ])
            })
            .catch(err => console.error(err))
            .finally(() => {
                this.$store.commit('SET_LOADING', false)
            })
    }

    addEmptyTag() {
        this.currentPage = Math.ceil(this.rows / this.perPage);
    }

    changePaginate(variant: { active: boolean, perPage: number }): void {
        this.perPageVariants.forEach((variant) => variant.active = false)
        this.perPage = variant.perPage
        variant.active = true
    }
}
