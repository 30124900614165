
import {Vue, Prop, Component} from "vue-property-decorator";
import TableColumn from "@/interfaces/TableColumn"
import TableHeaderComponent from "@/components/TableList/TableHeaderComponent.vue";
import AvitoRepubRowComponent from '@/components/TableList/AvitoRepubRowComponent.vue'
import Tag from '@/interfaces/Tag';

@Component({
    components: {
        TableHeaderComponent,
        AvitoRepubRowComponent,
    },
})
export default class TagsAvitoTableListComponent extends Vue {
    @Prop() tags!: Tag[]
    @Prop() columns?: TableColumn[]
    @Prop() perPage?: number
    @Prop() currentPage?: number

    get itemsPaginated(): Tag[] {
        if (!!this.perPage && !!this.currentPage && (this.perPage < this.tags.length)) {
            const perPage = this.perPage

            const start = (this.currentPage - 1) * perPage
            const end = start + perPage

            return this.tags.slice(start, end)
        }

        return this.tags
    }
}
