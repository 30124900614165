
import TableColumn from "@/interfaces/TableColumn"
import {Vue, Prop, Component} from "vue-property-decorator";
import TableItemComponent from "@/components/TableList/TableItemComponent.vue"
import AvitoCategory from "@/interfaces/AvitoCategory";

@Component({
    components: {
        TableItemComponent,
    },
})
export default class AvitoRepubRowComponent extends Vue {
    @Prop() index!: number;
    @Prop() row?: AvitoCategory;
    @Prop() columns?: TableColumn[];
}
