
import {Component, Vue, Prop} from "vue-property-decorator"
import TableColumn from "@/interfaces/TableColumn"
import TableDataComponent from "@/components/TableList/TableDataComponent.vue"

@Component({
    components: {
        TableDataComponent,
    }
})
export default class TableListComponent extends Vue {
    @Prop() index!: number;
    @Prop() item!;
    @Prop() columns!: TableColumn[];
}
