import NavLink from "@/interfaces/NavLink"
import RootState from "@/interfaces/RootState"
import TableItem from "@/interfaces/TableItem"
import MessengersItem from "@/interfaces/MessengersItem";
import ColumnsReferralsStatistics from "@/mocks/ColumnsReferralsStatistics"
import ColumnsArchivedUser from "@/mocks/ColumnsArchivedUser"
import ColumnsClientTables from "@/mocks/ColumnsClientTables"
import ColumnsClientMarketplaces from "@/mocks/ColumnsClientMarketplaces"
import ColumnsInvitations from "@/mocks/ColumnsInvitations"
import ColumnsReferrals from "@/mocks/ColumnsReferrals"
import ColumnsTransactions from "@/mocks/ColumnsTransactions"
import ColumnsAdminTransactions from "@/mocks/ColumnsAdminTransactions"
import ColumnsAllTables from "@/mocks/ColumnsAllTables"
import ColumnsUser from "@/mocks/ColumnsUser"
import ColumnsAdminUser from "@/mocks/ColumnsAdminUser"
import ColumnsStatisticsGeneral from "@/mocks/statistics/ColumnsStatisticsGeneral"
import ColumnsStatisticsPayments from "@/mocks/statistics/ColumnsStatisticsPayments"
import ColumnsStatisticsActivities from "@/mocks/statistics/ColumnsStatisticsActivities"
import ColumnsStatisticsActive from "@/mocks/statistics/ColumnsStatisticsActive"
import ColumnsStatisticsSleep from "@/mocks/statistics/ColumnsStatisticsSleep"
import ColumnsAvitoMonitoringTasks from "@/mocks/AvitoMonitoring/ColumnsAvitoMonitoringTasks"
import ColumnsMessengers from "@/mocks/ColumnsMessengers"
import ColumnsTagsAvito from "@/mocks/ColumnsTagsAvito"
import ColumnsAvitoCategories from "@/mocks/ColumnsAvitoCategories"
import ColumnsAvitoRepub from "@/mocks/ColumnsAvitoRepub"
import ColumnsAvitoRepub_v2 from "@/mocks/ColumnsAvitoRepub_v2"
import ColumnsAvitoRepubHistory_v2 from "@/mocks/ColumnsAvitoRepubHistory_v2"
import ColumnsAvitoServices from "@/mocks/ColumnsAvitoServices"
import ColumnsImagesTransform from "@/mocks/ColumnsImagesTransform"
import Auth from "@/store/modules/Auth"
import Generators from "@/store/modules/Generators"
import Help from "@/store/modules/Help"
import Invitations from "@/store/modules/Invitations"
import Referrals from "@/store/modules/Referrals"
import Statistics from "@/store/modules/Statistics"
import Transactions from "@/store/modules/Transactions"
import Users from "@/store/modules/Users/"
import Tags from "@/store/modules/Tags/"
import AvitoCategories from "@/store/modules/AvitoCategories/"
import AvitoRepub from "@/store/modules/AvitoRepub/"
import AvitoRepub_v2 from "@/store/modules/AvitoRepub_v2/"
import ImagesTransform from "@/store/modules/ImagesTransform/"
import AvitoServices from "@/store/modules/AvitoServices/"
import UwT from "@/store/modules/UwT/"
import AvitoMonitoring from "@/store/modules/AvitoMonitoring/"
import Wallet from "@/store/modules/Wallet"
import { RootActionContext, TableDetail } from "@/types/types"
import Vue from 'vue'
import Vuex from 'vuex'
import axios, { AxiosResponse } from "axios";
import router from "@/router";
import AppConfig from "@/interfaces/AppConfig";
import _ from "lodash-es";

Vue.use(Vuex)

const state: RootState = {
    hash: '',
    root: '/',
    configPath: '/assets/config.json',
    helpPath: '/data/help.json',
    // configPath: 'https://lk.adviz.pro/assets/config.json',
    appConfig: {} as AppConfig,
    msg: '',
    alert: '',
    showHeader: false,
    headerMessage: '',
    loading: false,
    initLoading: false,
    hashValidated: false,
    newTableCreated: true,
    verifySent: false,
    links: [],
    tables: [] as TableItem[],
    marketplaces: [] as TableItem[],
    tableDetail: {} as TableDetail,
    messengers: [] as MessengersItem[],
    columns: {
        user: ColumnsUser,
        adminUser: ColumnsAdminUser,
        allTables: ColumnsAllTables,
        clientTables: ColumnsClientTables,
        clientMarketplaces: ColumnsClientMarketplaces,
        archivedUser: ColumnsArchivedUser,
        transactions: ColumnsTransactions,
        adminTransactions: ColumnsAdminTransactions,
        invitations: ColumnsInvitations,
        referrals: ColumnsReferrals,
        referralsStatistics: ColumnsReferralsStatistics,
        statisticsGeneral: ColumnsStatisticsGeneral,
        statisticsActivities: ColumnsStatisticsActivities,
        statisticsActive: ColumnsStatisticsActive,
        statisticsSleep: ColumnsStatisticsSleep,
        statisticsPayments: ColumnsStatisticsPayments,
        avitoMonitoringTasks: ColumnsAvitoMonitoringTasks,
        messengers: ColumnsMessengers,
        tagsAvito: ColumnsTagsAvito,
        avitoCategories: ColumnsAvitoCategories,
        avitoRepub: ColumnsAvitoRepub,
        avitoRepub_v2: ColumnsAvitoRepub_v2,
        avitoRepubHistory_v2: ColumnsAvitoRepubHistory_v2,
        imagesTransform: ColumnsImagesTransform,
        avitoServices: ColumnsAvitoServices,
    },
    text: {
        addFirstTable: 'Кликните на кнопку ниже, что бы получить таблицу для автоматизации работы с досками объявлений.' +
            '<br><br>' +
            'Тестовый период 3 дня, а лимиты по 10 объявлений для Авито, Юла и Яндекс О.',
        addFirstMarketplace: 'Кликните на кнопку ниже, что бы получить таблицу для обновления остатков и цен на OZON',
        buttonAddTable: 'Создать таблицу',
        buttonAddMarketplace: 'Создать таблицу',
        buttonAddMarketplaceAdmin: 'Создать для OZON',
        buttonAddTableAdmin: 'Создать таблицу',
        tooManyTables: 'Ограничение по количеству таблиц',
        tooManyMarketplaces: 'Ограничение по количеству таблиц',
    },
    errorMessages: {
        noHash: 'Ошибка 401 Unauthorized (Отсутствует hash)<br><br>' +
            'Если хотите пользоваться сервисом, напишите в поддержку.<br>' +
            'Успехов в автоматизации 🤝<br><br>' +
            'Поддержка TG: <a href="https://t.me/AutoZ_Support_Bot">https://t.me/AutoZ_Support_Bot</a><br>' +
            'Поддержка ВК: <a href="https://vk.com/im?media=&sel=-142566585">https://vk.me/autoz_podderzhka</a>',

        wrongHash: 'Ошибка 403 Forbidden (Неправильный hash)<br><br>' +
            'Убедитесь, что ссылка введена верно и обратитесь в поддержку.<br>' +
            'Успехов в автоматизации 🤝<br><br>' +
            'Поддержка TG: <a href="https://t.me/AutoZ_Support_Bot">https://t.me/AutoZ_Support_Bot</a><br>' +
            'Поддержка ВК: <a href="https://vk.com/im?media=&sel=-142566585">https://vk.me/autoz_podderzhka</a>',

        error404: 'Ошибка 404 Not Found<br><br>' +
            'Что-то пошло не так.<br><br>' +
            'Поддержка TG: <a href="https://t.me/AutoZ_Support_Bot">https://t.me/AutoZ_Support_Bot</a><br>' +
            'Поддержка ВК: <a href="https://vk.com/im?media=&sel=-142566585">https://vk.me/autoz_podderzhka</a>',

        error500: 'Ошибка 500 Internal Server Error (Внутренняя ошибка сервера)<br><br>' +
            'Сервер не отвечает.<br>' +
            'Срочно напишите в поддержку.<br><br>' +
            'Поддержка TG: <a href="https://t.me/AutoZ_Support_Bot">https://t.me/AutoZ_Support_Bot</a><br>' +
            'Поддержка ВК: <a href="https://vk.com/im?media=&sel=-142566585">https://vk.me/autoz_podderzhka</a>',

        blocked: 'Ошибка 403 Forbidden (Заблокирован)<br><br>' +
            'Ваш профиль находится в архиве так как не используется по назначению.<br>' +
            'Если хотите пользоваться сервисом, напишите в поддержку.<br>' +
            'Успехов в автоматизации 🤝<br><br>' +
            'Поддержка TG: <a href="https://t.me/AutoZ_Support_Bot">https://t.me/AutoZ_Support_Bot</a><br>' +
            'Поддержка ВК: <a href="https://vk.com/im?media=&sel=-142566585">https://vk.me/autoz_podderzhka</a>',

        wrongCredentials: 'Ошибка 403 Forbidden (Неправильный E-mail или пароль)<br><br>' +
            'Убедитесь, что ввели верный E-mail и пароль и обратитесь в поддержку.<br>' +
            'Успехов в автоматизации 🤝<br><br>' +
            'Поддержка TG: <a href="https://t.me/AutoZ_Support_Bot">https://t.me/AutoZ_Support_Bot</a><br>' +
            'Поддержка ВК: <a href="https://vk.com/im?media=&sel=-142566585">https://vk.me/autoz_podderzhka</a>',

        tooManyRequests: 'Ошибка 429 (Слишком много запросов)<br><br>' +
            'Попробуйте еще раз через пару минут.<br><br>' +
            'Поддержка TG: <a href="https://t.me/AutoZ_Support_Bot">https://t.me/AutoZ_Support_Bot</a><br>' +
            'Поддержка ВК: <a href="https://vk.com/im?media=&sel=-142566585">https://vk.me/autoz_podderzhka</a>',
    },
    validationErrors: {
        userNotFound: 'Пользователь с таким E-mail\'ом не найден',
        emailAlreadyTaken: 'Такой E-mail уже занят',
    }
};

export default new Vuex.Store({
    state,
    mutations: {
        SET_HASH(state: RootState, hash: string): void {
            state.hash = hash
        },
        SET_CONFIG(state: RootState, appConfig: AppConfig): void {
            state.appConfig = appConfig;
        },
        SET_INIT_LOADING(state: RootState, payload: boolean): void {
            state.initLoading = payload
        },
        SET_LOADING(state: RootState, payload: boolean): void {
            state.loading = payload
        },
        SET_SHOW_HEADER(state: RootState, payload: boolean) {
            state.showHeader = payload
        },
        SET_HEADER_MESSAGE(state: RootState, payload: string) {
            state.headerMessage = payload
        },
        CLEAR_MSG(state: RootState): void {
            state.msg = ''
        },
        SET_MSG(state: RootState, msg: string): void {
            state.msg = msg
        },
        SET_MSG_FROM_STATUS_CODE(state: RootState, statusCode: number): void {
            switch (statusCode) {
                case 401:
                    if (state.hash !== '') {
                        state.msg = state.errorMessages.noHash
                    }
                    break
                case 403:
                    state.msg = state.errorMessages.wrongHash
                    break
                case 404:
                    state.msg = state.errorMessages.error404
                    break
                default:
                    if (statusCode >= 500) {
                        state.msg = state.errorMessages.error500
                    } else {
                        state.msg = ''
                    }
            }
        },
        SET_MSG_IS_BLOCKED(state: RootState): void {
            state.msg = state.errorMessages.blocked
        },

        SET_LINKS(state: RootState, links: []) {
            state.links = links
        },
        SET_TABLES(state: RootState, tables: TableItem[]) {
            state.tables = tables
        },
        SET_MESSENGERS(state: RootState, messengers: MessengersItem[]) {
            state.messengers = messengers
        },
        UPDATE_MESSENGER_TOKEN(state: RootState, messenger: MessengersItem) {
            const storeMessenger = _.find(state.messengers, {'id': messenger.id});
            if (storeMessenger) {
                storeMessenger.telegram_token = messenger.telegram_token;
            }
        },
        SET_MARKETPLACES(state: RootState, marketplaces: TableItem[]) {
            state.marketplaces = marketplaces
        },
        SET_TABLE_DETAIL(state: RootState, tableDetail: TableDetail) {
            state.tableDetail = tableDetail
        },
        SORT_TABLES_BY(
            state: RootState,
            { sort, desc = false }: { sort: keyof TableItem, desc: boolean }
        ): void {
            state.tables.sort((a: TableItem, b: TableItem) => {
                let bElement = b[sort];
                let aElement = a[sort];

                bElement = bElement ? bElement : 0
                aElement = aElement ? aElement : 0

                if (aElement === bElement) {
                    return 0
                }
                if (desc) {
                    return (aElement < bElement) ? 1 : -1;
                }
                return (aElement > bElement) ? 1 : -1;
            });
        },
        SORT_MARKETPLACES_BY(
            state: RootState,
            { sort, desc = false }: { sort: keyof TableItem, desc: boolean }
        ): void {
            state.marketplaces.sort((a: TableItem, b: TableItem) => {
                let bElement = b[sort];
                let aElement = a[sort];

                bElement = bElement ? bElement : 0
                aElement = aElement ? aElement : 0

                if (aElement === bElement) {
                    return 0
                }
                if (desc) {
                    return (aElement < bElement) ? 1 : -1;
                }
                return (aElement > bElement) ? 1 : -1;
            });
        },
        PUSH_NEW_TABLE(state: RootState, newTable: TableItem): void {
            state.tables.push(newTable)
        },
        PUSH_NEW_MARKETPLACE(state: RootState, newMarketplace: TableItem): void {
            state.marketplaces.push(newMarketplace)
        },
        UPDATE_DATE_EXPIRED(
            state: RootState, { tableId, dateExpired }: { tableId: number, dateExpired: number }
        ): void {
            const indexTables = state.tables.findIndex((table: TableItem) => table.tableId === tableId)
            if (indexTables !== -1) {
                state.tables[indexTables].dateExpired = dateExpired
                return
            }

            const indexMarketplaces = state.marketplaces.findIndex((table: TableItem) => table.tableId === tableId)
            if (indexMarketplaces !== -1) {
                state.marketplaces[indexMarketplaces].dateExpired = dateExpired
            }
        },
        UPDATE_GOOGLE_SHEET_ID(
            state: RootState, { tableId, googleSheetId }: { tableId: number, googleSheetId: string }
        ): void {
            const indexTables = state.tables.findIndex((table: TableItem) => table.tableId === tableId)
            if (indexTables !== -1) {
                state.tables[indexTables].googleSheetId = googleSheetId;
                state.tables[indexTables].googleSheetUrl = 'https://docs.google.com/spreadsheets/d/' + googleSheetId;
                return
            }

            const indexMarketplaces = state.marketplaces.findIndex((table: TableItem) => table.tableId === tableId)
            if (indexMarketplaces !== -1) {
                state.marketplaces[indexMarketplaces].googleSheetId = googleSheetId;
                state.marketplaces[indexMarketplaces].googleSheetUrl = 'https://docs.google.com/spreadsheets/d/'
                    + googleSheetId;
            }
        },
    },
    actions: {
        setHash({ commit }: RootActionContext): void {
            const res = <string | null>router.currentRoute.query.hash;
            const hash = res ? res : '';
            commit('SET_HASH', hash)
        },

        async setConfig({ state, commit }: RootActionContext): Promise<void> {
            const res = await axios.get<AxiosResponse<AppConfig>>(state.configPath);
            const config = await res.data;
            await commit('SET_CONFIG', config);
        },

        async setHeaderLinks({ dispatch, getters }: RootActionContext): Promise<void> {
            const isAdmin = await getters['Users/userIsAdmin'];
            const isAdminLite = await getters['Users/userIsAdminLite'];
            const isManager = await getters['Users/userIsManager'];

            const links = [
                {
                    group: '',
                    accessible: !isAdmin && !isAdminLite && !isManager,
                    items: [
                        {
                            name: 'autoload',
                            title: 'Автозагрузка',
                            to: state.root + 'autoload' + getters.hashQuery,
                            accessible: !isAdmin && !isAdminLite && !isManager,
                        }
                    ]
                },
                {
                    group: '',
                    accessible: false,
                    items: [
                        {
                            name: 'marketplaces',
                            title: 'Маркетплейсы',
                            to: state.root + 'marketplaces' + getters.hashQuery,
                            accessible: false,
                        }
                    ]
                },
                {
                    group: 'Пользователи',
                    accessible: isAdmin || isAdminLite || isManager,
                    items: [
                        {
                            name: 'users',
                            title: 'Активные',
                            to: state.root + 'users' + getters.hashQuery,
                            accessible: isAdmin || isAdminLite || isManager,
                        },
                        {
                            name: 'users/archived',
                            title: 'Архивированные',
                            to: state.root + 'users/archived' + getters.hashQuery,
                            accessible: isAdmin || isAdminLite || isManager,
                        },
                        {
                            name: 'users/admins',
                            title: 'Администраторы',
                            to: state.root + 'users/admins' + getters.hashQuery,
                            accessible: isAdmin,
                        }
                    ]
                },
                {
                    group: '',
                    accessible: isAdmin || isAdminLite || isManager,
                    items: [
                        {
                            name: 'tags/avito',
                            title: 'Теги Авито',
                            to: state.root + 'tags/avito' + getters.hashQuery,
                            accessible: isAdmin || isAdminLite || isManager,
                        }
                    ]
                },
                {
                    group: '',
                    accessible: isAdmin || isAdminLite,
                    items: [
                        {
                            name: 'avito/categories',
                            title: 'Категории Авито',
                            to: state.root + 'avito/categories' + getters.hashQuery,
                            accessible: isAdmin || isAdminLite,
                        }
                    ]
                },
                {
                    group: '',
                    accessible: isAdmin || isAdminLite || isManager,
                    items: [
                        {
                            name: 'system/settings',
                            title: 'Настройки системы',
                            to: state.root + 'system/settings' + getters.hashQuery,
                            accessible: isAdmin || isAdminLite || isManager,
                        }
                    ]
                },
                {
                    group: 'SMS',
                    accessible: isAdmin || isAdminLite || isManager,
                    items: [
                        {
                            name: 'sms/send',
                            title: 'Отправка',
                            to: state.root + 'sms/send' + getters.hashQuery,
                            accessible: isAdmin || isAdminLite || isManager,
                        },
                        {
                            name: 'sms/templates',
                            title: 'Шаблоны уведомлений',
                            to: state.root + 'sms/templates' + getters.hashQuery,
                            accessible: isAdmin || isAdminLite || isManager,
                        },
                    ]
                },
                {
                    group: '',
                    accessible: false,
                    items: [
                        {
                            name: 'messengers',
                            title: 'Центр сообщений Авито',
                            to: state.root + 'messengers' + getters.hashQuery,
                            accessible: true,
                        }
                    ]
                },
                {
                    group: '',
                    accessible: false,
                    items: [
                        {
                            name: 'system/logs',
                            title: 'Просмотр логов',
                            to: state.root + 'system/logs' + getters.hashQuery,
                            accessible: isAdmin || isAdminLite || isManager,
                        }
                    ]
                },
                {
                    group: '',
                    accessible: !isAdmin && !isAdminLite && !isManager,
                    items: [
                        {
                            name: 'about',
                            title: 'О сервисе',
                            to: state.root + 'about' + getters.hashQuery,
                            accessible: !isAdmin && !isAdminLite && !isManager,
                        }
                    ]
                }
            ];

            await dispatch('setLinks', links)
        },

        async getFullUrl(
            { state }: RootActionContext,
            path: string
        ): Promise<string> {
            return state.appConfig.apiRoot + path;
        },

        setLinks({ commit }: RootActionContext, links: []): void {
            commit('SET_LINKS', links)
        },

        addNewTable({ commit }: RootActionContext, table: TableItem): void {
            commit('PUSH_NEW_TABLE', table)
        },

        addNewMarketplace({ commit }: RootActionContext, marketplace: TableItem): void {
            commit('PUSH_NEW_MARKETPLACE', marketplace)
        },

        async redirectToHome({ rootState, rootGetters, getters }: RootActionContext) {
            const isAdmin = await getters['Users/userIsAdmin'];
            const isAdminLite = await getters['Users/userIsAdminLite'];
            const isManager = await getters['Users/userIsManager'];

            const query = rootGetters.hasHash ? { hash: rootState.hash } : {};

            if (
                !rootGetters['Users/hasEmail'] ||
                (rootGetters['Users/hasEmail'] && !rootGetters['Users/hasVerifyEmail'])
            ) {
                router.push({ name: 'autoload', query })
                return
            }

            if (isAdmin || isAdminLite || isManager) {
                router.push({name: 'users', query})
            } else {
                router.push({name: 'about', query})
            }
        },


        async authenticate({ dispatch, commit, getters }: RootActionContext, redirect: boolean) {
            if (!await dispatch('checkAuth')) {
                if (router.currentRoute.name !== 'login') {
                    if (redirect) {
                        await router.push({ name: 'login' })
                    }
                }
                return
            }

            await dispatch('Users/setUserInfo')

            if (
                getters['hasHash'] &&
                getters['Users/hasUser'] &&
                getters['Users/hasEmail']
            ) {
                commit('SET_HASH', '', { root: true })
                await router.replace({ query: undefined })
                await dispatch('Users/logout')
                await dispatch('setLinks', [] as NavLink[])
                commit('SET_SHOW_HEADER', false)

                if (router.currentRoute.name !== 'login') {
                    await router.push({ name: 'login' })
                }
                return
            }

            if (!getters['Users/hasUser']) {
                // if (getters['Users/isUserBlocked']) {
                //     commit('SET_MSG_IS_BLOCKED')
                //     return
                // }

                commit('SET_MSG_FROM_STATUS_CODE', 403)
                return
            }

            await dispatch('setHeaderLinks')

            if (!getters['Users/hasUserInfo']) {
                await dispatch('Users/redirectIfNoUserInfo')
                return
            }

            await commit('SET_SHOW_HEADER', true)
        },

        async checkAuth({ commit, dispatch, getters }: RootActionContext): Promise<boolean> {
            if (await dispatch('authenticateOAuth2')) {
                if (getters.hasHash) {
                    commit('SET_HASH', '', { root: true })
                    await router.replace({ query: undefined })
                }
                return true
            }

            return getters.hasHash;
        },

        async authenticateOAuth2({ dispatch, getters }: RootActionContext): Promise<boolean> {
            await dispatch('Auth/setTokenFromStorage')
            if (!getters['Auth/hasToken']) {
                return false
            }

            if (!getters['Auth/tokenExpired']) {
                return true
            }
            await dispatch('Auth/refreshToken')

            return true
        }
    },
    modules: {
        Auth,
        Wallet,
        Invitations,
        Referrals,
        Transactions,
        Statistics,
        Generators,
        UwT,
        Users,
        Tags,
        AvitoCategories,
        AvitoRepub,
        AvitoRepub_v2,
        ImagesTransform,
        AvitoServices,
        Help,
        AvitoMonitoring,
    },
    getters: {
        hasHash(state: RootState): boolean {
            return !!state.hash;
        },

        hashQuery(state: RootState): string {
            const hash = state.hash;
            return hash ? ('?hash=' + hash) : '';
        },

        showContent(state: RootState): boolean {
            return !state.msg && !state.loading
        },

        userColumnsLength(state: RootState): number {
            return state.columns.user.length + 1;
        }
    },

})
