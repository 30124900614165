
import { Component, Vue, Prop } from "vue-property-decorator"
import {Action, Getter} from "vuex-class";
import AvitoCategory from "@/interfaces/AvitoCategory";

@Component
export default class TDAvitoCategorySave extends Vue {
    @Prop() item!

    @Getter("AvitoCategories/getFixUpdate") fixUpdate!: string;

    @Action("AvitoCategories/saveCategory") saveCategoryAction!: (category: AvitoCategory) => Promise<void>;

    static componentName = 'TDAvitoCategorySave'

    saveCategory(): void {
        this.saveCategoryAction(this.item)
            .then(() => {})
            .catch(err => console.error(err));
    }
}
